/*!
                         _____                                                   
  /  _  \   ____   ____   ____ _____    ____               
 /  /_\  \_/ __ \ / ___\_/ __ \\__  \  /    \              
/    |    \  ___// /_/  >  ___/ / __ \|   |  \             
\____|__  /\___  >___  / \___  >____  /___|  /             
        \/     \/_____/      \/     \/     \/              
  _________      .__          __  .__                      
 /   _____/ ____ |  |  __ ___/  |_|__| ____   ____   ______
 \_____  \ /  _ \|  | |  |  \   __\  |/  _ \ /    \ /  ___/
 /        (  <_> )  |_|  |  /|  | |  (  <_> )   |  \\___ \ 
/_______  /\____/|____/____/ |__| |__|\____/|___|  /____  >                                                                                                                                                                                                                                                                                                                 
=========================================================
* Aegean Solutions - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Marios Kaldellis

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import Sizedbox from 'components/Sizedbox';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Progress,
  Button
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlinePerson4,
  MdAdd
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      
    </Box>
  );
}
