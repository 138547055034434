const styles = {
    default: {
        horizontal: {
            width: '100%',
        },
        vertical: {
            height: '100%',
        },
    },
};

export default styles;

